import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'daterangepicker/daterangepicker';
import { OdsImage } from "./odsimage"
import { OdsDocument } from "./odsdocument"


export class OdsComponents {

  // Initialisation des composants particuliers
  initComponents(){
    
    const image = new OdsImage();
    image.deleteImageLinks("photo-product");
    image.deleteImageLinks("photo-signature");
    image.inputFileLabel($('.custom-file-input'));
    image.previewImageInputFile($('.preview-file-input'));

    this.initInputDocuments();
    this.initListProductsButton();
    this.initSelectProducts();
    this.initCopyLendComponent();
    this.initSubCategories();
    this.initLinkIfEmpty();
  }

  // Initialisation du composants sur les sous catégories
  initSubCategories(){
    $('.select-category').each(function(){
      
      // Reload subcategory items
      $(this).change(function(){
        var select_subcat =  $(this).parents('.row').find('.select-subcategory');

        $.ajax({
          type: "GET",
          url: "/categories/" + $(this).val(),
          dataType: "json",
          success: function(data){
            select_subcat.find('option').remove();
            $.each(data, function(idx, subcat){
              var option = "<option value='" + subcat.id + "'>" + subcat.name + "</option>"
              select_subcat.append(option);
            });
          }        
        });
      });
    });

    $('.subcategories-edit-list').each(function(){
      var subList = $(this);

      $("input[type=text]", subList).each(function(){
        if($(this).val() == ''){
          $(this).parents('li').hide();
        }// if
      });

      $('.add-new-subcategory', subList).click(function(e){
        $("input[type=text]", subList).each(function(){
          if($(this).val() == ''){
            $(this).parents('li').show();
            $(this).focus();
            return false;
          };
        })
        e.preventDefault();
      });

      $('.input-group-append input', subList).change(function(){
        $(this).parent().toggleClass('btn-danger');
      });
    });
  }// initSubCategories

  // Initialisation du composant de copy d'un prêt
  initCopyLendComponent(){
    $('.copy-lend').each(function(){
      
      var comCopy = $(this);
      $('a.btn.btn-info', comCopy).click(function(e){

        var products = [];
        $("input:checked", comCopy).each(function(){
          products.push($(this).val());
        });

        var href = $(this).attr('href') + '?products=' + products.join(',');
        window.location = href;
        e.preventDefault();
      });
    });
  }

  // Initialisation de la liste des produits
  initSelectProducts(){
    
    // Prise en compte de la colonne checkbox
    $.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
    {
      return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return $('input', td).prop('checked') ? '1' : '0';
      } );
    }

    var options = {
      "dom" : "<if<t>>",
      paging: false,
      "order": [[ 0, "desc" ]],
      "columnDefs": [
        {
          targets: [0],
          orderDataType: 'dom-checkbox'
        }
      ],
      "language": this.defineDataTableLocalization(),
      "initComplete": function () {
        var api = this.api();
        api.$('td input[type=checkbox]').each( function () {
          if ($(this).is(':checked')){
            $(this).parents('tr').toggleClass('selected');
          }
          if ($(this).is(':disabled')){
            $(this).parents('tr').toggleClass('disabled');
          }
        
        } );

        api.$('td input[type=checkbox]').change( function () {
          $(this).parents('tr').toggleClass('selected');
        } );
      }
    };

    // Initialisation de la datatable
    $('.select-products table.table').each(function(){
      var table = $(this);
      var tableProducts = null;
      var dataOptions = Object.assign({}, options);

      if (table.hasClass('overflow')){
        dataOptions["scrollY"] = '50vh';
        dataOptions["scrollCollapse"] = true;
        tableProducts = table.DataTable(dataOptions);
      }else{
        dataOptions["searching"] = false;
        table.DataTable(dataOptions);
      }

      // Reinit de la recherche sur le bouton submit pour enregistrement
      if (table.hasClass('overflow')){ // Que pour la table des produits
        $("input[type='submit'").click(function(e){
          if (tableProducts != null){
            console.log('submit');
            tableProducts.search('').draw();
          }
        });
      }
    });
  }

  // Initialisation du bouton qui masque une partie de la liste du matériel des prêts
  initListProductsButton(){
    $('.list-products .btn-sm').click(function (e) {
      var button = $(this);
      console.log(button.parents(('td')).find('.list-products li:hidden').length);
      button.parents('td').find('.list-products li:hidden').show();
      button.remove();
      e.preventDefault();
    });
  }

  // Initialisation des inputs des documents
  initInputDocuments(){
    $('.input-document').each(function(){
      const document = new OdsDocument(this);
      document.init();
    });
  }

  
  // Initialisation d'une datatable
  initDatatables(datatable){
    var dt = datatable.DataTable({
      "stateSave": true,
      "dom" : "<'row'<'col-sm-6'i><'col-sm-6'f>>" + "<tr>" + "<'row'<'col-sm-6'l><'col-sm-6'p>>",
      "lengthMenu": [[100, 200, 300, -1], [100, 200, 300, "Tout"]],
      "language": this.defineDataTableLocalization()
    });

    return dt;
  }

  // Initialisation des liens si les textarea sont vides
  initLinkIfEmpty(){
    $('textarea.link-if-empty').each(function(){
      var textarea = $(this);
      if(textarea.val().length == 0){
        textarea.hide();
        var label = textarea.parents('.form-group').find('label').text();
        var link = "<a href='#' class='btn-sm'>Ajouter un(e) " + label + "</a>";
        
        textarea.parent().append(link);
        // TODO : J'aurai préféré un $(link).click(...), mais ça ne marche po !
        textarea.parent().find('a').click(function(){
          $(this).hide();
          textarea.show();
          return false;
        });
      }
    });
  }

  // Définit la traduction du datatable
  defineDataTableLocalization(){
    const options = {
      "sEmptyTable":     "Aucune donnée disponible dans le tableau",
      "sInfo":           "_START_ à _END_ sur _TOTAL_ éléments",
      "sInfoEmpty":      "0 à 0 sur 0 élément",
      "sInfoFiltered":   "(filtré à partir de _MAX_ éléments au total)",
      "sInfoPostFix":    "",
      "sInfoThousands":  ",",
      "sLengthMenu":     "Afficher _MENU_ éléments",
      "sLoadingRecords": "Chargement...",
      "sProcessing":     "Traitement...",
      "sSearch":         "Rechercher :",
      "sZeroRecords":    "Aucun élément correspondant trouvé",
      "oPaginate": {
          "sFirst":    "Premier",
          "sLast":     "Dernier",
          "sNext":     "Suivant",
          "sPrevious": "Précédent"
      },
      "oAria": {
          "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
          "sSortDescending": ": activer pour trier la colonne par ordre décroissant"
      },
      "select": {
              "rows": {
                  "_": "%d lignes sélectionnées",
                  "0": "Aucune ligne sélectionnée",
                  "1": "1 ligne sélectionnée"
              } 
      }
    }

    return options;
  }

  // Initialisation du datetime picker
  initDateTimePicker(datepicker){
    datepicker.daterangepicker({
      "singleDatePicker": true,
      "showWeekNumbers": true,
      "locale": {
          "format": "DD/MM/YYYY",
          "separator": " - ",
          "weekLabel": "S",
          "daysOfWeek": [
              "Di",
              "Lu",
              "Ma",
              "Me",
              "Je",
              "Ve",
              "Sa"
          ],
          "monthNames": [
              "Janvier",
              "Février",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Juillet",
              "Août",
              "Septembre",
              "Octobre",
              "Novembre",
              "Décembre"
          ],
          "firstDay": 1
      }
    });
  }
}
