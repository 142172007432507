export class OdsImage {
  deleteImageLinks(image_name){
    $("a#delete-" + image_name).click(function(event){
      $('img#image-'+ image_name).remove();
      $('input#delete-' + image_name).val("true");
      $(this).remove();
      event.preventDefault() 
    });
  }

  // Affiche le nom du fichier pour les contrôles input file type boostrap
  inputFileLabel(inputs){
    inputs.change(function(){
      var label = $(this).parent().find('label');
      if (label.attr('none_file') == null) label.attr('none_file', label.html());
      
      var fileNames = [];
      $.each($(this).prop('files'), function(){ fileNames.push(this.name); });

      if(fileNames.length > 0)        label.html(fileNames.join(','));
      else if(fileNames.length == 0)  label.html(label.attr('none_file'));
    });
  }

  // Initialise les liens qui doivent afficher un élément
  linkShowElement(link){
    link.click(function(event){
      $('.' + $(this).data('show')).removeClass('d-none');
      $(this).remove();
      event.preventDefault();
    });
  }

  // Initialise les files inputs preview
  // IMPROVE : Composant dédié à faire, avec l'affichage du nom de l'élément
  previewImageInputFile(previewInputs){
    previewInputs.change(function(){
      if (this.files && this.files[0]) {
        var reader = new FileReader();
        const img = $(this).parent().find('img.d-none');
        const controlDelete = $(this).parent().parent().find('.control-delete');
        const controlImage = $(this).parent().parent().find('.control-image');

        reader.onload = function(e) {
          img.attr('src', e.target.result);
          img.removeClass('d-none');
          controlDelete.addClass('d-none');
          controlImage.addClass('d-none');
        }
        
        reader.readAsDataURL(this.files[0]); // convert to base64 string
      }
    });
  }
}