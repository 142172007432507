// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'
require.context('../images', true)

import $ from 'jquery';
global.$ = $;
global.jQuery = $;
import "jquery.easing/jquery.easing";
import 'bootstrap/dist/js/bootstrap';
import '../libs/select2.min.js';

import { OdsComponents } from "./odscomponents"

document.addEventListener("turbolinks:before-cache", function() {
  $('#dataTable').DataTable().destroy();
});

$(document).on('turbolinks:load', function() {
  initComponents();
});

$.fn.select2.defaults.set( "theme", "bootstrap" );

// Initialisation des composants
function initComponents(){
  // Thème Bootstrap
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });
  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });
  // Initialisation de la validation de bootstrap 
  var forms = document.getElementsByClassName('needs-validation');
  // Loop over them and prevent submission
  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });

  $('#detailProduct').modal();

  // Composants Ods
  const app = new OdsComponents();

  // Datatable
  if ($('.dataTables_info').length == 0) {
    app.initDatatables($('#dataTable'));
  }

  // InitDatetime picker
  app.initDateTimePicker($('.datepicker'));
  $('.select-autocomplete').select2();
  
  // Initialisation des composants
  app.initComponents();
}


