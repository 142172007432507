// Représente un composant de type document avec bouton de suppression
export class OdsDocument {
  constructor(input_file){
    this.input_file = $(input_file);
  }

  init(){
    this.delete_button = this.input_file.parents('tr').find('a[document-confirm]');
    this.input_destroy = this.input_file.parents('tr').find("input[type='hidden']");
    this.document_name = this.input_file.parents('tr').find('.document-name');

    const document = this;

    this.delete_button.click(function(e){
      if(confirm($(this).attr('document-confirm'))){
        
        document.document_name.remove();
        document.input_file.removeClass('d-none');
        document.input_destroy.val('true');
        
        $(this).tooltip('dispose')
        $(this).remove();
      }
      e.preventDefault();
    });
  }
}